@font-face {
  font-family: 'Proxima nova' !important;
  src: url('./Assets/fonts/proximanova-regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

:root {
  --primary-white: #FFFFFF;
  --blue-789ED1: #789ED1;
  --blue-5A80BD: #5A80BD;
  --blue-446FB2: #446FB2;
  --blue-1E224D: #1E224D;
  --primary-black: #121A21;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Proxima nova', sans-serif !important;
  margin: 0;
  width: 100%;
  height: 100%;
}